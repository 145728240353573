// extracted by mini-css-extract-plugin
export var assetLink = "releases-module--assetLink--orYXk";
export var content = "releases-module--content--h9c7v";
export var open = "releases-module--open--hsDxd";
export var openIcon = "releases-module--openIcon--oExwH";
export var release = "releases-module--release--mgjFD";
export var releaseAssets = "releases-module--releaseAssets--ZHgU1";
export var releaseDate = "releases-module--releaseDate--AW-BR";
export var releaseHeader = "releases-module--releaseHeader--XdFWw";
export var releaseListContainer = "releases-module--releaseListContainer--Bgk-+";
export var releaseName = "releases-module--releaseName--QFIJx";
export var releaseSection = "releases-module--releaseSection--+1AyK";